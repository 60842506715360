<template>
  <div
    class="hero-slider__slide"
    :style="{
      backgroundImage:
        'url(' + heroData.fields.image.fields.umbracoFile.src + ')',
    }"
  >
    <div class="inner">
      <div class="hero__content">
        <ul itemscope="" itemtype="http://schema.org/BreadcrumbList" class="breadcrumb ">
          <li itemprop="itemListElement" itemscope="" itemtype="http://schema.org/ListItem">
            <a itemprop="item" href="/at-the-airport/airport-services/" tabindex="0"><span itemprop="name">{{ heroData.fields.subtitle }}</span></a>
            <meta itemprop="position" content="1">
          </li>
        </ul>
        <h1 class="hero__heading">{{ heroData.fields.title }}</h1>
        <div class="hero-form hero-form--booking hero-form--mobile">
          <button type="submit" class="btn btn--primary" tabindex="0">Book Car Hire</button>
        </div>
        <div class="hero-form hero-form--booking hero-form--desktop">
          <div class="grid">
            <div class="grid__half">
              <label for="pickUpLocation">Pick-up location</label>
              <Select2
                :options="['Airport 1', 'Airport 2', 'Airport 3']"
                :settings="{
                  placeholder: 'Please select',
                }"
                v-model="pickUpLocation"
              />
            </div>
          </div>
          <div class="grid">
            <div class="grid__half">
              <div class="grid">
                <div class="grid__half">
                  <label for="pickUpDate">Pick-up date</label>
                  <DatePicker
                    v-model="pickUpDate"
                    :min-date="new Date()"
                    :masks="{ input: this.displayFormat }"
                    class="datepicker"
                    :popover="{ visibility: 'focus' }"
                    is-required
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input :value="inputValue" v-on="inputEvents" />
                    </template>
                  </DatePicker>
                </div>
                <div class="grid__half">
                  <div class="grid grid--inner">
                    <div class="grid__half">
                      <label for="pickUpHour"><span class="hiddenFromView">Pick-up</span> time <span class="hiddenFromView">hours</span></label>
                      <Select2
                        :options="['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']"
                        v-model="pickUpHour"
                      />
                    </div>
                    <label for="pickUpMinute"><span class="hiddenFromView">Pick-up time minutes</span></label>
                    <div class="grid__half">
                      <Select2
                        :options="['00', '15', '30', '45']"
                        v-model="pickUpMinute"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid__half">
              <div class="grid">
                <div class="grid__half">
                  <label for="dropOffDate">Drop-off date</label>
                  <DatePicker
                    v-model="dropOffDate"
                    :min-date="new Date()"
                    :masks="{ input: this.displayFormat }"
                    class="datepicker"
                    :popover="{ visibility: 'focus' }"
                    is-required
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input :value="inputValue" v-on="inputEvents" />
                    </template>
                  </DatePicker>
                </div>
                <div class="grid__half">
                  <div class="grid grid--inner">
                    <div class="grid__half">
                      <label for="dropOffHour"><span class="hiddenFromView">Drop-off</span> time <span class="hiddenFromView">hours</span></label>
                      <Select2
                        :options="['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']"
                        v-model="dropOffHour"
                      />
                    </div>
                    <label for="dropOffMinute"><span class="hiddenFromView">Drop-off time minutes</span></label>
                    <div class="grid__half">
                      <Select2
                        :options="['00', '15', '30', '45']"
                        v-model="dropOffMinute"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn--primary" tabindex="0">Book Car Hire</button>
          <a href="#" class="btn btn--secondary" tabindex="0">More information</a>
        </div>
      </div>
      <div class="booking-module__error" data-message="time-slot-not-available">
        Sorry, no availability for your chosen timeslot.
      </div>
    </div>
    <div class="hero__gradient"></div>
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import { DatePicker } from "v-calendar";

export default {
  name: "HeroCar",
  props: ["heroData"],
  data() {
    return {
      pickUpLocation: null,
      pickUpDate: null,
      pickUpHour: '00',
      pickUpMinute: '00',
      dropOffDate: null,
      dropOffHour: '00',
      dropOffMinute: '00',
    };
  },
  components: {
    Select2,
    DatePicker,
  },
};
</script>

<style lang="scss">
.grid--inner {
  .grid__half {
    clear: none !important;
    margin: 0 !important;
    width: 50%;
  }
}

// hide from view but keep for screen readers
.hiddenFromView {
  display: inline-block;
  text-indent: -9999px;
}
</style>
