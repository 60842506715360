<template>
  <div class="index-sheet">
    <h2>Pages</h2>
    <ul>
      <li><router-link to="/">Home</router-link></li>
      <li><router-link to="/content-page/">Content Page</router-link></li>
      <li><router-link to="/styleguide">Styleguide</router-link></li>
    </ul>
    <h2>Components (/conponents/x/)</h2>
    <ul>
      <li>
        <router-link to="/components/large-width-frame"
          >Large Width Frame</router-link
        >
      </li>
      <li>
        <router-link to="/components/promo-block">Promo Block</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
};
</script>

<style scoped>
.index-sheet {
  max-width: 1160px;
  margin: 60px auto 0;
  font-size: 18px;
  padding: 0 20px;
  text-align: left;
}
ul {
  margin: 4rem 0;
}
ul li {
  margin-bottom: 20px;
}
h1 {
  font-size: 50px;
}
h2 {
  font-size: 28px;
}
h1,
h2 {
  margin-bottom: 30px;
}
code {
  background: black;
  color: white;
  padding: 3px;
}
</style>
