<template>
  <section>
    <div class="panel-padding">
      <div class="inner centre-text cf" v-if="componentData.fields.title || componentData.fields.intro">
        <h1 v-if="componentData.fields.title">{{ componentData.fields.title }}</h1>
        <div v-html="componentData.fields.intro"></div>        
      </div>
      <div class="inner">
        <vue-collapsible-panel-group accordion>
          <vue-collapsible-panel
            v-for="(item, index) in componentData.fields.items"
            :key="index"
            :expanded="false"
          >
            <template #title>
              <h3>
                {{ item.fields.title }}
              </h3>
            </template>
            <template #content>
              <div>
                <div v-html="item.fields.body"></div>
              </div>
            </template>
          </vue-collapsible-panel>
        </vue-collapsible-panel-group>
      </div>
    </div>
  </section>
</template>

<script>
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel,
} from "@dafcoe/vue-collapsible-panel";

export default {
  name: "SectionedContentList",
  props: ["componentData"],
  components: {
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// This is content added within the CMS

.vcp {
  border-left: 1px solid $sectioned-content-list-header-border;
  border-right: 1px solid $sectioned-content-list-header-border;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: $sectioned-content-list-header-bg;
    border-top: 1px solid #fff;
    border-bottom: 1px solid $sectioned-content-list-header-border;
    padding: 2rem 1.5rem;
    cursor: pointer;

    @include breakpoint($tablet){
      padding: 2rem 3rem;
    }

    h3 {
      margin: 0;
      font-size: 1.6rem;
      padding-right: 1rem;
      line-height: 1.3;
      color: $sectioned-content-list-header-color;

      @include breakpoint($tablet){
        font-size: 1.8rem;
      }
    }

    &-icon {
      margin-left: auto;
    }

    &:hover,
    &:focus {
      background: rgb(230, 230, 230);
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  &--expanded {
    .vcp__header {
      background: $skin-colour-primary;
      color: $sectioned-content-list-header-color-active;

      h3 {
        color: $sectioned-content-list-header-color-active;
      }
    }
    .vcp__header-icon {
      transform: rotate(180deg);
    }
  }

  &__body-content {
    padding: 2rem;
    font-size: 1.4rem;

    @include breakpoint($tablet){
        font-size: 1.6rem;
        padding: 2rem 3rem;
      }

    p {
      font-size: 1.4rem;

      @include breakpoint($tablet){
        font-size: 1.8rem;
      }
    }
  }

  &:last-of-type {
    border-bottom: 1px solid $sectioned-content-list-header-border;
  }
}
.rich-text-block {
  h3:not(.big-text),
  h4:not(.big-text),
  h5:not(.big-text),
  h6:not(.big-text) {
    text-transform: uppercase;
    font-size: 1.6rem;
    color: $content-sidebar-heading;
    margin-bottom: 1rem;
  }

  h2 {
    margin-bottom: 3rem;
  }

  p {
    margin-bottom: 3rem;
    @include font-size($p-font-sizes);

    a:not(.btn) {
      color: $links;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  hr {
    height: 1px;
    background: $keyline;
    margin: 25px 0;
    display: block;
  }

  table {
    background: $light-bg;
    padding: 1.5rem 0.5rem;
    font-size: 1.5rem;
    margin: 1rem 0 3rem;
    width: 100%;
    max-width: 45rem;

    th,
    td {
      font-weight: $reg;
      padding: 0.5rem;
      text-align: center;

      .icon {
        margin: 0;
      }
    }
  }

  ul,
  ol {
    margin: 2rem 0;

    li {
      display: block;
      margin: 0 0 1.5rem;
      @include font-size($p-font-sizes);
      position: relative;
      padding-left: 1.5rem;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 6px;
        left: 0;
        background: $skin-colour-primary;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }

      a {
        color: $btn-link;
      }
    }
  }
}
</style>
