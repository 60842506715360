<template>
  <div v-if="info.fields">
    <template v-for="(component, index) in info.fields.components" :key="index">
      <component
        :is="component.system.contentType"
        :componentData="component"
        v-if="
          component.system.contentType.toLowerCase() ==
          componentName.toLowerCase()
        "
      ></component>
    </template>
  </div>
</template>


<script>
// @ is an alias to /src
import LargeWidthFrame from "@/components/Selectable/LargeWidthFrame.vue";
import PromoBlock from "@/components/Selectable/PromoBlock.vue";
import RichTextBlock from "@/components/Selectable/RichTextBlock.vue";
import ThreeColumnImageBlockList from "@/components/Selectable/ThreeColumnImageBlockList.vue";
import ProductList from "@/components/Selectable/ProductList.vue";
import axios from "axios";

/* eslint-disable vue/no-unused-components */
export default {
  name: "SingleComponentDemoPage",
  components: {
    LargeWidthFrame,
    PromoBlock,
    RichTextBlock,
    ThreeColumnImageBlockList,
    ProductList,
  },
  data() {
    return {
      info: [],
      componentName: "",
      webpage: "http://hangar-poc-backend.kmpassociates.co.uk/",
    };
  },
  mounted() {
    this.componentName = this.$route.params.slug.replace(/-/g, "");
    axios
      .get(
        "/dummy-contentpage.json" // This will be swapped out for the real API feed later
      )
      .then((response) => (this.info = response.data));
  },
};
</script>