<template>
  <swiper
    :slides-per-view="1"
    :space-between="0"
    :loop="heroCollection.length > 1"
    :autoplay="{
      delay: duration,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    }"
    :allow-touch-move="false"
    :navigation="heroCollection.length > 1"
    :pagination="{
      clickable: true,
    }"
  >
    <swiper-slide v-for="(hero, index) in heroCollection" :key="index">
      <component :is="hero.system.contentType" :heroData="hero"></component>
    </swiper-slide>
  </swiper>
</template>
<script>
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import HeroPlain from "@/components/HeroSlides/HeroPlain.vue";
import HeroParkingFeatures from "@/components/HeroSlides/HeroParkingFeatures.vue";
import HeroProduct from "@/components/HeroSlides/HeroProduct.vue";
import HeroCar from "@/components/HeroSlides/HeroCar.vue";
import HeroCruise from "@/components/HeroSlides/HeroCruise.vue";

// TODO - decide if all of these are needed
import "@/assets/scss/plugins/swiper/swiper.scss";
import "@/assets/scss/plugins/swiper/components/navigation/navigation.scss";
import "@/assets/scss/plugins/swiper/components/pagination/pagination.scss";
// import "swiper/components/scrollbar/scrollbar.scss";

SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

export default {
  name: "HeroSlider",
  components: {
    Swiper,
    SwiperSlide,
    HeroPlain,
    HeroParkingFeatures,
    HeroProduct,
    HeroCar,
    HeroCruise
  },
  props: ["heroCollection", "duration"],
};
</script>

<style lang="scss">
:root {
  --swiper-navigation-size: 30px;
  --swiper-navigation-color: white;
}

.swiper-button-prev,
.swiper-button-next {
  display: none;

  @include breakpoint($desktop-sml) {
    display: block;
  }
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
  bottom: 2rem;

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: $slider-dots-bg;
    opacity: 1;

    &-active {
      background: $slider-dots-bg-active;
    }
  }

  // @include breakpoint($desktop-sml) {
  //   left: auto;
  //   right: 1.5rem;
  //   top: 8.5rem;
  //   bottom: auto;
  //   width: auto;
  // }
}
</style>
