<template>
    <section class="mailing-list panel-padding">
      <div class="inner">
        <header class="mailing-list__intro">
          <h2 class="mailing-list__heading">{{ componentData.fields.title }}</h2>
          <div v-html="componentData.fields.intro"></div>
        </header>
        <form method="post" v-on:submit="submitForm">
          <div class="grid">
            <div class="grid__half" v-if="!componentData.fields.hideStandardNameField">
              <label for="mailingListName">{{this.labels.value.labelName}} *</label>
              <input id="mailingListName" type="text" :placeholder="this.labels.value.labelName" v-model="mailingListName" required />
            </div>            
            <div class="grid__half">
              <label for="mailingListEmail">{{this.labels.value.labelEmail}} *</label>
              <input id="mailingListEmail" type="email" :placeholder="this.labels.value.labelEmail" v-model="mailingListEmail" required />
            </div>            

            <template v-for="(item, index) in componentData.fields.additionalFields" :key="index">
              <div class="grid__full checkbox" v-if="item.fields.type.toLowerCase() === 'checkbox'">
                <input :id="'field_' + index" name="mailingListAdditionalField" v-model="mailingListAdditionalField[index]" type="checkbox" :data-required="item.fields.mandatory" :data-required-message="item.fields.placeholder">
                <label :for="'field_' + index" v-html="item.fields.label"></label>
              </div>
              <div class="grid__half" v-else>
                <label :for="'field_' + index">{{item.fields.label}}</label>
                <input :id="'field_' + index" name="mailingListAdditionalField" v-model="mailingListAdditionalField[index]" :type="item.fields.type.toLowerCase()" :placeholder="item.fields.placeholder" :required="item.fields.mandatory" />
              </div>
            </template>

            <div class="grid__full checkbox">
              <input id="mailingListTermsConditions" type="checkbox" data-required="true" :data-required-message="this.labels.value.validationTerms">
              <label for="mailingListTermsConditions" v-html="componentData.fields.terms"></label>
            </div>
            <div class="grid__full">
              <input type="submit" class="btn btn--primary" :value="componentData.fields.buttonText">
            </div>
          </div>
          <div class="mailing-list__error" v-show="this.errorVisible">{{errorMessage}}</div>
        </form>
      </div>
    </section>
</template>

<script>

import axios from "axios";
import responseCalculator from "../../mixins/responseCalculator";

export default {
  name: "MailingListBlock",
  props: ["componentData"],
  inject: ["labels"],
  mixins: [responseCalculator],
  methods: {
    submitForm(e) {

      e.preventDefault();
      console.log(e);
      var isValid = e.target.checkValidity();
      var checkboxes = document.querySelectorAll('input[type=checkbox]');
      checkboxes.forEach(checkbox => {
        console.log(checkbox);
        console.log(checkbox.dataset.required);
        if (checkbox.dataset.required && checkbox.dataset.requiredMessage && isValid)
        {
          if (!checkbox.checked)
          {
            alert(checkbox.dataset.requiredMessage);
            isValid = false;
          }
        }
      });

      if (isValid)
      {
        axios
        .post(
          process.env.VUE_APP_REMOTEBASEURL +  "/api/FormApi/AddToMailingList", {
            email: this.mailingListEmail,
            name: this.mailingListName,
            additionalFields: this.mailingListAdditionalField,
            componentId: this.componentData.system.id,
            cResponse: this.calculatedResponse,
            iataCode: process.env.VUE_APP_IATACODE
          }
        )
        .then((response) => {
          if (response.data){
            if (this.componentData.fields.thanksPage)
            {
              window.location.href = this.componentData.fields.thanksPage.url;
            } else {
              // Most people will use the thanks page, but this gives us a fallback
              this.errorMessage = this.labels.value.labelFormSuccess;
              this.errorVisible = true;
            }
          } else {
            this.errorMessage = this.labels.value.labelFormError;
            this.errorVisible = true;
          }
        });
      }
    }
  },
  data() {
    return {
      errorMessage: "",
      errorVisible: false,
      mailingListName: "",
      mailingListEmail: "",
      mailingListAdditionalField: []
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.mailing-list {
  background-color: $skin-colour-primary;
  color: $promo-module-color;

  &__heading {
    color: $promo-module-color !important;
    font-size: 3.2rem;

    @include breakpoint($desktop-sml) {
      font-size: 4.6rem;
    }
  }

  &__error {
    background: #f1cece;
    border: 1px solid #ff1101;
    color: #ff1101;
    display: block;
    font-size: 14px;
    padding: 1rem 2rem;
    text-align: center;

    @include breakpoint($tablet-sml) {
      display: inline-block;
    }
  }

  label {
    color: $promo-module-color;
  }

  input {
    margin-bottom: 2rem;
  }

  .checkbox {
    margin-bottom: 2rem;
  }
}
</style>
