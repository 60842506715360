/* Modified from https://github.com/jacobwarduk/equal-heights/tree/develop/src */
/* To use in a component.
1) Add 'ref='component' to an outer wrapper on the component
2) import matchHeights from "../../mixins/matchHeights";
3) Add mixins: [matchHeights]
4) Add 'data-match-height='x' to whatever elements you want to match where x is a group name. Multiple groups supported

KNOWN LIMITATIONS CURRENTLY:-
* Later loaded elements such as images may not be calculated
* Nesting matched height elements may be unpredictable

*/

export default {
    methods: {
      matchHeight: function() {
        var component = this.$refs.component;
        var groupName = Array.prototype.slice.call(
            component.querySelectorAll('[data-match-height]')
          ),
          groupHeights = {};          
  
        for (var item of groupName) {
          var data = item.getAttribute('data-match-height');
          item.style.minHeight = 'auto';
  
          if (Object.prototype.hasOwnProperty.call(groupHeights,data)) {
            Object.defineProperty(groupHeights, data, {
              value: Math.max(groupHeights[data], item.offsetHeight),
              configurable: true,
              writable: true,
              enumerable: true
            });
          } else {
            groupHeights[data] = item.offsetHeight;
          }
        }
  
        var groupHeightsMax = groupHeights;
  
        Object.getOwnPropertyNames(groupHeightsMax).forEach(function(value) {
          var elementsToChange = component.querySelectorAll(
              "[data-match-height='" + value + "']"
            ),
            elementsLength = elementsToChange.length;
  
          for (var i = 0; i < elementsLength; i++) {
            elementsToChange[i].style.height =
              Object.getOwnPropertyDescriptor(groupHeightsMax, value).value +
              'px';
          }
        });
     }
    },
    mounted() {
      this.matchHeight();
      window.addEventListener("resize", this.matchHeight);
    }
  }
