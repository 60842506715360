<template>
  <div
    class="hero-slider__slide"
    :style="{
      backgroundImage:
        'url(' + heroData.fields.image.fields.umbracoFile.src + ')',
    }">
    <div class="inner">
      <div class="hero__content">
        <ul itemscope="" itemtype="http://schema.org/BreadcrumbList" class="breadcrumb ">
          <li itemprop="itemListElement" itemscope="" itemtype="http://schema.org/ListItem">
            <a itemprop="item" href="/at-the-airport/airport-services/" tabindex="0"><span itemprop="name">{{ heroData.fields.subtitle }}</span></a>
            <meta itemprop="position" content="1">
          </li>
        </ul>
        <h1 class="hero__heading">{{ heroData.fields.title }}</h1>
        <div class="hero-form hero-form--booking hero-form--mobile">
          <button type="submit" class="btn btn--primary" tabindex="0">Book Now</button>
        </div>
        <div class="hero-form hero-form--booking hero-form--desktop">
          <div class="grid">
            <div class="grid__half">
              <div class="grid">
                <div class="grid__half">
                  <label for="entryDate">Date</label>
                  <DatePicker
                    v-model="entryDate"
                    :min-date="new Date()"
                    :masks="{ input: this.displayFormat }"
                    class="datepicker"
                    :popover="{ visibility: 'focus' }"
                    is-required
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input :value="inputValue" v-on="inputEvents" />
                    </template>
                  </DatePicker>
                </div>
                <div class="grid__half">
                  <label for="entryTime">Time</label>
                  <Select2
                      class="selectbox-time"
                      :options="['06:30 - 07:00', '07:00 - 07:30', '07:30 - 08:00', '08:00 - 08:30', '08:30 - 09:00', '09:00 - 09:30', '09:30 - 10:00', '10:00 - 10:30', '10:30 - 11:00', '11:00 - 11:30', '11:30 - 12:00', '12:00 - 12:30', '12:30 - 13:00', '13:00 - 13:30', '13:30 - 14:00', '14:00 - 14:30', '14:30 - 15:00', '15:00 - 15:30', '15:30 - 16:00', '16:00 - 16:30', '16:30 - 17:00', '17:00 - 17:30', '17:30 - 18:00']"
                      :settings="{
                        placeholder: this.labels.value.labelTime,
                      }"
                      v-model="entryTime"
                    />
                </div>
              </div>
            </div>
            <div class="grid__half">
              <div class="grid">
                <div class="grid__half">
                  <label for="loungeAdults">Adults (12+)</label>
                  <Select2
                      class="selectbox"
                      :options="['1', '2', '3', '4', '5', '6']"
                      :settings="{
                        placeholder: '0',
                      }"
                      v-model="loungeAdults"
                    />
                </div>
                <div class="grid__half">
                  <label for="loungeChild">Children (3-11)</label>
                  <Select2
                      class="selectbox"
                      :options="['0', '1', '2', '3', '4', '5', '6']"
                      :settings="{
                        placeholder: '0',
                      }"
                      v-model="loungeChild"
                    />
                </div>
              </div>
            </div>
          </div>
          <div class="grid">
            <div class="grid__full">
              <button type="submit" class="btn btn--primary" tabindex="0">Book Now</button>
            </div>
          </div>
        </div>
        <div class="booking-module__error" data-message="time-slot-not-available">
          Sorry, no availability for your chosen timeslot.
        </div>
      </div>
    </div>
    <div class="hero__gradient"></div>
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import { DatePicker } from "v-calendar";
import parkingForm from "../../mixins/parkingForm";

export default {
  name: "HeroProduct",
  props: ["heroData"],
  data() {
    return {
      entryDate: '',
      entryTime: '',
      loungeAdults: '1',
      loungeChild: '0',
    };
  },
  components: {
    Select2,
    DatePicker,
  },
  mixins: [parkingForm],
};
</script>

<style lang="scss">
.hero {
  &__gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-image: linear-gradient(
      -107deg,
      rgba(0, 0, 0, 0.46) 0%,
      rgba(0, 0, 0, 0.13) 93%
    );
  }

  &__heading {
    color: #fff;
    @extend h1;

    @include breakpoint($tablet) {
      max-width: 75%;
    }

    @include breakpoint($desktop-sml) {
      max-width: none;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 72rem;

    @include breakpoint($desktop) {
      max-width: 82rem;
    }

    .btn--primary {
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  p {
    font-size: 1.9rem;
    margin-top: -1.5rem;
    font-family: $font-headings;
    margin-bottom: 3rem;
    color: $white;

    @include breakpoint($tablet) {
      max-width: 75%;
    }

    @include breakpoint($desktop-sml) {
      font-size: 3rem;
      line-height: 1.2;
      max-width: none;
    }
  }
}

.hero-form {
  &__col {
    display: inline-block;
    vertical-align: top;
    width: 50%;

    .calendar {
      border-right: 1px solid #e0e4f0;
    }

    input,
    .select2-selection--single,
    .select2-selection__rendered {
      width: 18.5rem;
      border: none;
      height: 50px;
      line-height: 50px;
      font-size: 1.8rem;
    }
  }

  &--mobile {
    @include breakpoint($tablet) {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @include breakpoint($tablet) {
      display: unset;
    }
  }

  &--booking {
    label {
      color: #fff;
    }

    .grid {
      margin-bottom: 1rem;
    }

    .grid__half {
      float: left !important;

      input,
      .select2-selection--single,
      .select2-selection__rendered {
        width: 100%;
      }

      .calendar {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
      }

      .time {
        padding-left: 0.1rem;
      }

      .grid {
        margin-bottom: 0;

        .grid__half {
          margin-bottom: 1rem;
        }
      }
    }

    .btn,
    a {
      margin-top: 1rem;
    }
  }
}

.selectbox-time + .select2-container .select2-selection__arrow {
  display: none;
}

.selectbox-time + .select2-container .select2-selection__rendered {
  font-weight: 400;

  @media (max-width: 899px) {
    border: none;
    line-height: 48px;
  }
}

.booking-module__error {
  padding: 10px 20px;
  display: block;
  margin-top: 2rem;
  background: #f1cece;
  color: #ff1101;
  border: 1px solid #ff1101;
  font-size: 14px;
  text-align: center;

  @include breakpoint($tablet-sml) {
    display: inline-block;
    max-width: none;
  }
}
</style>
