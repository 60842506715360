<template>
  <section class="panel-padding">
    <header>
      <div class="inner -activecf">
        <h2 class="heading centre-text">{{ componentData.fields.title }}</h2>
      </div>
    </header>
    <div class="inner">
      <div class="grid">
        <div
          class="grid__third"
          :class="{'dimmed' : componentData.fields.applyDimming}"
          v-for="item in componentData.fields.pagePanels"
          :key="item.id"
        >
          <!-- Link may not always be set, so wrapping an a tag around the whole thing might not be the best idea -->
          <article class="image-block">
            <a
              v-if="item.fields.link"
              :href="item.fields.link.url"
              :target="item.fields.link.target"
            ></a>
            <div class="image-block__content">
              <h3 class="image-block__title">{{ item.fields.title }}</h3>
              <p class="image-block__intro">{{ item.fields.subtitle }}</p>
            </div>
            <span class="btn" v-if="item.fields.link">{{
              item.fields.link.name
            }}</span>
            <div
              class="image-block__image"
              :aria-label="item.fields.image.fields.alt"
              :style="{
                backgroundImage:
                  'url(' + item.fields.image.fields.umbracoFile.src + ')',
              }"
            />
          </article>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ThreeColumnImageBlockList",
  props: ["componentData"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.image-block {
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
  height: 0;
  padding-top: 100%;
  display: block;
  background: $skin-colour-primary;

  > a {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &:focus-visible > a {
    outline: -webkit-focus-ring-color auto 1px;
    outline-offset: 1px;
  }

  @include breakpoint($tablet-sml) {
    margin-bottom: 20px;
  }

  .dimmed & {
    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
    }
  }

  

  &:hover,
  &:focus {
    

    .btn {
      background: darken($btn-primary, 5%);
      color: $btn-primary-color;
    }

    &::before {
      background: rgba(0, 0, 0, 0.5);
    transition: background 0.25s linear;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
    padding: 0;
    z-index: 1;

    background: {
      size: cover;
      repeat: no-repeat;
      position: 50% 50%;
    }
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    padding: 15px 25px;
  }

  &__title {
    font-size: 3.2rem;
    font-weight: $bold;
    margin-bottom: 20px;
    color: $white;
    line-height: 1.1;

    @include breakpoint($tablet) {
      font-size: 2.5rem;
    }

    @include breakpoint($desktop-sml) {
      font-size: 3.2rem;
    }
  }

  &__intro {
    font-size: 1.4rem;
    font-weight: $reg;
    margin: 0;
    color: #fff;
    line-height: 1.3;

    @include breakpoint($tablet) {
      display: none;
    }

    @include breakpoint($desktop-sml) {
      display: block;
    }
  }

  .btn {
    @extend .btn--primary;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    font-size: 18px;
    padding: 1.25rem;
    min-width: 20rem;
  }
}
</style>
