<template>
  <Head v-if="info.fields">
    <!-- Modify page head attributes using https://github.com/vueuse/head -->
    <title>{{ info.fields.metaTitle }}</title>
    <meta name="description" :content="info.fields.metaDescription" />
    <meta name="keywords" :content="info.fields.metaKeywords" />
    <meta name="title" :content="info.fields.metaTitle" />
    <meta name="robots" :content="info.fields.metaRobots" v-if="info.fields.statusCode == 200" />
    <meta name="robots" content="noindex" v-else />
    <meta name="prerender-status-code" :content="info.fields.statusCode" />
    <meta name="prerender-header" :content="'Location: ' + redirectUrlWithBase" v-if="info.fields.statusCode == 301" />
    <meta http-equiv="refresh" :content="'0; url=' + info.fields.redirectUrl" v-if="info.fields.statusCode == 301" />
    <link rel="icon" v-if="loadedIcon" :href="loadedIcon" />
    <link
      rel="apple-touch-icon-precomposed"
      sizes="144x144"
      v-if="loadedIcon"
      :href="loadedIcon"
    />
    <link
      rel="apple-touch-icon-precomposed"
      sizes="114x114"
      v-if="loadedIcon"
      :href="loadedIcon"
    />
    <link
      rel="apple-touch-icon-precomposed"
      sizes="72x72"
      v-if="loadedIcon"
      :href="loadedIcon"
    />
    <link
      rel="apple-touch-icon-precomposed"
      v-if="loadedIcon"
      :href="loadedIcon"
    />
  </Head>
  <div v-if="info.fields">
    <Header
      :publicSiteSettings="info.fields.publicSiteSettings"
      v-if="info.fields.showHeader"
    />

    <StickyBookingBar v-if="info.fields.showPersistentParkingForm" />

    <!-- custom component to basically wrap up all the hero carousel functionality into one line -->
    <HeroSlider
      :duration="8000"
      :heroCollection="info.fields.heros"
      v-if="info.fields.heros"
    ></HeroSlider>

    <main id="content">

      <template
        v-for="(component, index) in info.fields.components"
        :key="index"
      >
        <component
          :is="component.system.contentType"
          :componentData="component"
        ></component>
      </template>
    </main>
    <Footer
      :publicSiteSettings="info.fields.publicSiteSettings"
      v-if="info.fields.showFooter"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import StickyBookingBar from "@/components/StickyBookingBar.vue";
import StickySalesBar from "@/components/StickySalesBar.vue";
import HeroSlider from "@/components/HeroSlider.vue";
import axios from "axios";
import { Head } from "@vueuse/head";
import { computed } from "vue";

// Selectable components
import LargeWidthFrame from "@/components/Selectable/LargeWidthFrame.vue";
import PromoBlock from "@/components/Selectable/PromoBlock.vue";
import RichTextBlock from "@/components/Selectable/RichTextBlock.vue";
import ThreeColumnImageBlockList from "@/components/Selectable/ThreeColumnImageBlockList.vue";
import ProductList from "@/components/Selectable/ProductList.vue";
import SectionedContentList from "@/components/Selectable/SectionedContentList.vue";
import LiveFlightList from "@/components/Selectable/LiveFlightList.vue";
import LiveFlightSearchBlock from "@/components/Selectable/LiveFlightSearchBlock.vue";
import IconCallToActionList from "@/components/Selectable/IconCallToActionList.vue";
import TwoAreaContentBlock from "@/components/Selectable/TwoAreaContentBlock.vue";
import MailingListBlock from "@/components/Selectable/MailingListBlock.vue";

// Default labels
import localLabels from "@/labels.json";

/* eslint-disable vue/no-unused-components */
export default {
  name: "ContentPage",
  components: {
    Footer,
    Header,
    Head,
    StickyBookingBar,
    StickySalesBar,    
    HeroSlider,
    LargeWidthFrame,
    PromoBlock,
    RichTextBlock,
    ThreeColumnImageBlockList,
    ProductList,
    SectionedContentList,
    LiveFlightList,
    LiveFlightSearchBlock,
    IconCallToActionList,
    TwoAreaContentBlock,
    MailingListBlock
  },
  data() {
    return {
      info: [],
      labels: [],
      webpage: ""
    };
  },
  computed: {
    loadedIcon: function () {
      return this.info.fields.publicSiteSettings.fields.icon
        ? this.info.fields.publicSiteSettings.fields.icon.fields.umbracoFile.src
        : "";
    },
    redirectUrlWithBase: function() {
      if (this.info.fields.redirectUrl) {
        return this.info.fields.redirectUrl.startsWith("http") ?
          this.info.fields.redirectUrl :
          window.location.protocol + "//" + window.location.host + this.info.fields.redirectUrl;
      }
      else
      {
        return "";
      }
    }
  },
  provide() {
    return {
      publicSiteSettings: computed(() => ({
        ...this.info.fields.publicSiteSettings,
      })), 
      labels: computed(() =>
      ({
        // Start with local labels file and override with remote settings if available
        ...this.labels,
      }))   
    };
  },
  mounted() {
    this.webpage = this.$route.params.slug
      ? "/" + this.$route.params.slug.replace(/\/?$/, "/")
      : "/"; // if the URL has more on it, use this to extract that
    axios
      .get(process.env.VUE_APP_REMOTEBASEURL +
              "/api/headlessapi/getpage?url=" +
              this.webpage +
              "&iataCode=" +
              process.env.VUE_APP_IATACODE
      )
      .then((response) => {
        this.info = response.data;

        // Update or add to local labels with remote data
        this.labels = localLabels;
        if (response.data.fields.publicSiteSettings.fields.labels)
        {
          response.data.fields.publicSiteSettings.fields.labels.forEach((label) =>
          {
            this.labels[label.fields.itemKey] = label.fields.itemValue;
          })
        }         
      });
  },
};
</script>

<style lang="scss">
.hero-slider {
  overflow-y: hidden;

  &__slide {
    position: relative;
    color: $white;
    height: 65rem;

    background: {
      repeat: no-repeat;
      position: 50% 50%;
      size: cover;
    }

    .inner {
      top: 50%;
      transform: translateY(-50%);
      position: relative;
      z-index: 2;
      padding: 0 2rem; //  min-height: 25rem;

      @include breakpoint($tablet) {
        padding: 0 4rem; //  min-height: 25rem;
      }

      @include breakpoint($desktop) {
        padding: 0 0;
      }
    }
  }
}

.vc-popover-content-wrapper .is-today {
  .vc-day-content {
    color: $datepicker-today !important;
  }

  .vc-highlight {
    background: none !important;
  }
}
</style>
