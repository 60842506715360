<template>

<svg v-if="name === 'arrow'" width="18" height="16" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg"><title>Arrow</title><g stroke-width="2" stroke="#00A8E1" fill="none" fill-rule="evenodd" stroke-linecap="square"><path d="M2 8.12L15.011 8M9.314 1.442l6.442 6.569M9.314 14.558l6.442-6.569"/></g></svg>

<svg v-else-if="name === '360view'"  width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>360 View</title><g fill="#00A8E1" fill-rule="evenodd"><path d="M6.646 11.347c.014-.322.21-.665.574-.665.385 0 .602.322.602.672 0 .441-.231.728-.672.728h-.112v.735l.189-.014c.462-.035.742.42.742.84 0 .413-.245.847-.707.847-.455 0-.735-.35-.756-.77H5.47c.056.945.784 1.666 1.75 1.666 1.008 0 1.757-.644 1.757-1.68 0-.483-.196-1.078-.68-1.288.358-.238.505-.693.505-1.099 0-.91-.61-1.49-1.52-1.49-.944 0-1.525.608-1.616 1.518h.98zm4.844-1.596l-1.4 2.08c-.343.51-.644 1.133-.644 1.763 0 1.057.805 1.792 1.848 1.792 1.085 0 1.862-.777 1.862-1.862 0-.91-.602-1.75-1.568-1.75-.147 0-.294.021-.413.07l-.014-.014 1.085-1.533-.756-.546zm-.203 4.795c-.518 0-.84-.51-.84-.98 0-.476.322-.987.84-.987s.833.511.833.987c0 .47-.315.98-.833.98zm4.08-4.718c-1.413 0-1.89 1.652-1.89 2.786 0 1.127.49 2.772 1.89 2.772s1.89-1.645 1.89-2.772c0-1.134-.475-2.786-1.89-2.786zm0 .896c.736 0 .883 1.344.883 1.876 0 .525-.147 1.89-.882 1.89-.728 0-.882-1.365-.882-1.89 0-.532.154-1.876.882-1.876z"/><g fill-rule="nonzero"><path d="M14.711 3.43a9.256 9.256 0 0 1 5.407 4.617 9.29 9.29 0 1 1-12.495-4.06l-.908-1.782C1.159 5.036-1.05 11.835 1.78 17.391c2.83 5.556 9.63 7.765 15.185 4.934 5.556-2.83 7.765-9.63 4.934-15.186a11.256 11.256 0 0 0-6.57-5.612L14.71 3.43z"/><path d="M18.671 2.132l-.684-1.88-5.142 1.872L14.766 7.4l1.88-.684-1.237-3.398z"/></g></g></svg>

<svg v-else-if="name === 'download'" width="16" height="14" viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg"><title>Download</title><g fill="#FFF" fill-rule="evenodd"><path d="M0 12h16v2H0z"/><path d="M0 10h2v4H0zM14 10h2v4h-2zM8 9L5 5h6L8 9zM7 0h2v5H7V0z"/></g></svg>

<svg v-else-if="name === 'youtube'" width="22" height="15" viewBox="0 0 22 15" xmlns="http://www.w3.org/2000/svg"><title>YouTube</title><path d="M21.78 3.236S22 4.962 22 6.687v1.618c0 1.727-.22 3.452-.22 3.452s-.215 1.47-.874 2.117c-.837.85-1.775.853-2.205.903C15.623 14.993 11 15 11 15s-5.72-.05-7.48-.215c-.49-.088-1.589-.062-2.426-.911-.66-.647-.874-2.117-.874-2.117S0 10.032 0 8.305V6.687c0-1.725.22-3.451.22-3.451s.215-1.47.874-2.117C1.93.269 2.868.265 3.299.216 6.377 0 10.995 0 10.995 0h.01s4.618 0 7.696.216c.43.05 1.368.053 2.205.903.659.647.874 2.117.874 2.117zM8.938 10.557l5.944-2.985-5.944-3.007v5.992z" fill="#FFF" fill-rule="evenodd"/></svg>

<svg v-else-if="name === 'rss'" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><title>rss</title><path d="M2.74 16a2.74 2.74 0 1 1 0-5.48 2.74 2.74 0 0 1 0 5.48zm11.89 0a1.37 1.37 0 0 1-1.37-1.37c0-7.335-4.556-11.89-11.89-11.89a1.37 1.37 0 0 1 0-2.74c4.331 0 8 1.384 10.625 4.005C14.62 6.625 16 10.3 16 14.63A1.37 1.37 0 0 1 14.63 16zm-5.185 0a1.37 1.37 0 0 1-1.37-1.37c0-4.449-2.256-6.705-6.705-6.705a1.37 1.37 0 0 1 0-2.74c6.001 0 9.445 3.442 9.445 9.444A1.37 1.37 0 0 1 9.445 16z" fill="#FFF" fill-rule="evenodd"/></svg>

<svg v-else-if="name === 'email'" width="23" height="16" viewBox="0 0 23 16" xmlns="http://www.w3.org/2000/svg"><title>email</title><g fill="#FFF" fill-rule="evenodd"><path d="M2.477 0l9.37 7.408L21.214 0z"/><path d="M12.543 9.68a1.121 1.121 0 0 1-1.393 0L.692 1.413v12.92c0 .608.5 1.102 1.116 1.102h20.077A1.11 1.11 0 0 0 23 14.332V1.412L12.543 9.68z"/></g></svg>

<svg v-else-if="name === 'instagram'" width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"><title>instagram</title><g fill="#FEFEFE" fill-rule="evenodd"><path d="M10.662 0C7.766 0 7.403.012 6.266.064 5.13.116 4.356.296 3.678.56a5.226 5.226 0 0 0-1.889 1.23A5.226 5.226 0 0 0 .56 3.677C.297 4.356.117 5.13.065 6.266.012 7.403 0 7.766 0 10.662c0 2.895.012 3.258.064 4.396.052 1.134.232 1.91.496 2.588a5.226 5.226 0 0 0 1.23 1.888 5.226 5.226 0 0 0 1.888 1.23c.678.263 1.453.444 2.588.495 1.137.052 1.5.065 4.396.065 2.895 0 3.258-.013 4.396-.065 1.134-.051 1.91-.232 2.587-.495a5.225 5.225 0 0 0 1.889-1.23 5.226 5.226 0 0 0 1.23-1.888c.263-.679.444-1.454.495-2.588.052-1.138.064-1.5.064-4.396s-.012-3.259-.064-4.396c-.051-1.135-.232-1.91-.495-2.588a5.226 5.226 0 0 0-1.23-1.889A5.225 5.225 0 0 0 17.645.56c-.678-.263-1.453-.443-2.587-.495C13.92.012 13.558 0 10.662 0m0 1.921c2.847 0 3.184.01 4.308.062 1.04.048 1.604.221 1.98.367.497.194.853.425 1.226.798.373.373.604.728.797 1.226.146.375.32.94.367 1.98.052 1.124.062 1.46.062 4.308 0 2.847-.01 3.184-.062 4.308-.047 1.04-.22 1.604-.367 1.98a3.303 3.303 0 0 1-.797 1.226 3.303 3.303 0 0 1-1.226.797c-.376.146-.94.32-1.98.367-1.124.052-1.461.062-4.308.062-2.847 0-3.184-.01-4.309-.062-1.039-.047-1.604-.22-1.98-.367a3.303 3.303 0 0 1-1.225-.797 3.304 3.304 0 0 1-.798-1.226c-.146-.376-.32-.94-.367-1.98-.051-1.124-.062-1.461-.062-4.308 0-2.847.01-3.184.062-4.309.048-1.039.221-1.604.367-1.98.194-.497.425-.852.798-1.225a3.303 3.303 0 0 1 1.226-.798c.375-.146.94-.32 1.98-.367 1.124-.051 1.46-.062 4.308-.062"/><path d="M10.662 14.216a3.554 3.554 0 1 1 0-7.108 3.554 3.554 0 0 1 0 7.108m0-9.03a5.475 5.475 0 1 0 0 10.95 5.475 5.475 0 0 0 0-10.95"/></g></svg>

<svg v-else-if="name === 'twitter'" width="20" height="16" viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>twitter</title><defs><path id="a" d="M9.857 15.817h9.857V0H0v15.817h9.857z"/></defs><g fill="none" fill-rule="evenodd"><mask id="b" fill="#fff"><use xlink:href="#a"/></mask><path d="M6.2 15.817c7.44 0 11.508-6.085 11.508-11.36 0-.174-.003-.346-.011-.517a8.17 8.17 0 0 0 2.017-2.067 8.156 8.156 0 0 1-2.323.628A4.016 4.016 0 0 0 19.17.292a8.17 8.17 0 0 1-2.568.97A4.065 4.065 0 0 0 13.649 0c-2.233 0-4.045 1.788-4.045 3.993 0 .313.036.618.105.91A11.533 11.533 0 0 1 1.372.731a3.94 3.94 0 0 0-.548 2.007c0 1.386.714 2.608 1.8 3.324a4.055 4.055 0 0 1-1.832-.5v.051c0 1.934 1.394 3.549 3.244 3.915a4.106 4.106 0 0 1-1.826.068 4.043 4.043 0 0 0 3.778 2.774 8.183 8.183 0 0 1-5.023 1.709A8.33 8.33 0 0 1 0 14.023a11.558 11.558 0 0 0 6.2 1.794" fill="#FFF" mask="url(#b)"/></g></svg>

<svg v-else-if="name === 'linkedin'" width="18" height="17" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg"><title>linkedin</title><path d="M.22 16.458h3.69V5.353H.22v11.105zM2.09 0C.826 0 0 .829 0 1.918c0 1.066.802 1.92 2.04 1.92h.025c1.288 0 2.089-.854 2.089-1.92C4.13.828 3.353 0 2.09 0zm15.134 10.09v6.368h-3.692v-5.941c0-1.493-.534-2.511-1.87-2.511-1.02 0-1.627.686-1.894 1.35-.098.238-.123.568-.123.9v6.202H5.952s.05-10.062 0-11.105h3.693v1.574l-.024.036h.024v-.036c.49-.755 1.367-1.835 3.328-1.835 2.43 0 4.25 1.587 4.25 4.998z" fill="#FFF" fill-rule="evenodd"/></svg>

<svg v-else-if="name === 'facebook'" width="10" height="21" viewBox="0 0 10 21" xmlns="http://www.w3.org/2000/svg"><title>facebook</title><path d="M9.645 6.721H6.36V4.567c0-.81.537-.998.914-.998h2.318V.012L6.4 0C2.856 0 2.05 2.653 2.05 4.35v2.371H0v3.665h2.05v10.371h4.31v-10.37h2.91l.375-3.666z" fill="#FFF" fill-rule="evenodd"/></svg>

<svg v-else-if="name === 'google-assistant'"  height="24" viewBox="0 0 25 24" width="25" xmlns="http://www.w3.org/2000/svg"><title>google assistant</title><g fill="#fff"><circle cx="7.630273" cy="7.651611" r="7.630273"/><path d="m25 8.39599609c0 .85664063-.6945312 1.55087891-1.5508789 1.55087891s-1.5508789-.69423828-1.5508789-1.55087891c0-.85659179.6945312-1.5508789 1.5508789-1.5508789s1.5508789.69428711 1.5508789 1.5508789z"/><path d="m22.2084473 12.180127c0 1.7129394-1.3885743 3.1016601-3.101709 3.1016601-1.7132813 0-3.1017578-1.3887207-3.1017578-3.1016601 0-1.7130372 1.3885254-3.10175786 3.1017578-3.10175786 1.7131836 0 3.101709 1.38876956 3.101709 3.10175786z"/><path d="m22.8287598 20.1825684c0 2.055664-1.666211 3.7219238-3.7220703 3.7219238-2.0558594 0-3.7221192-1.6662598-3.7221192-3.7219238 0-2.0555664 1.666211-3.7220215 3.7221192-3.7220215 2.0558593 0 3.7220703 1.6664551 3.7220703 3.7220215z"/></g></svg>

<svg v-else-if="name === 'alexa'" height="21" viewBox="0 0 20 21" width="20" xmlns="http://www.w3.org/2000/svg"><title>alexa</title><path d="m9.97389034 0c-5.24077883.04353741-9.56378732 4.11607133-9.91967847 9.34493351-.35589114 5.22886219 3.37542895 9.84959649 8.56197612 10.60284719v-2.0365535c0-.5744126-.36553525-1.0966058-.88772846-1.2532638-3.41082287-1.1583015-5.38501529-4.7167818-4.56240422-8.22373004.82261107-3.50694825 4.17322359-5.81641 7.74334279-5.33721935 3.5701193.47919064 6.1931486 3.59045005 6.0618813 7.19019229v.0522193c.0015432.0870253.0015432.1740712 0 .2610966v.0522193c.0006217.0522175.0006217.1044405 0 .156658.0010712.0696216.0010712.1392556 0 .2088773v.1044386c-.9399478 5.6396867-8.30287209 8.8250653-8.35509141 8.8250653.44971287.0652242.90330475.1001158 1.35770235.1044386 5.53726746 0 10.02610966-4.4888422 10.02610966-10.0261096 0-5.53726751-4.4888422-10.0261097-10.02610966-10.0261097" fill="#fff"/></svg>

<svg v-else-if="name === 'menu'" width="24" height="16" viewBox="0 0 24 16" xmlns="http://www.w3.org/2000/svg"><title>menu</title><g fill="#FFF" fill-rule="evenodd"><path d="M0 0v2h24V0zM0 7v2h24V7zM0 14v2h24v-2z"/></g></svg>

<svg v-else-if="name === 'close'" width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><title>close icon</title><path d="M8.273 7L14 1.273 12.727 0 7 5.727 1.273 0 0 1.273 5.727 7 0 12.727 1.273 14 7 8.273 12.727 14 14 12.727 8.273 7z" fill="#FFF" fill-rule="evenodd"/></svg>

<svg v-else-if="name === 'plane'" width="20" height="19" viewBox="0 0 20 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Plane icon</title><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Icons/Icon24-Flights-darkblue" transform="translate(-2.000000, -3.000000)" fill="#223467"><g id="Group-2" transform="translate(13.390165, 11.515165) rotate(-315.000000) translate(-13.390165, -11.515165) translate(4.390165, 1.015165)"><path d="M6.91637011,12.1350365 L6.99822064,17.33966 L4.46085409,19.354972 C4.41992883,19.3952783 4.37900356,19.4557377 4.37900356,19.516197 L4.37900356,20.8664561 C4.37900356,20.9470686 4.46085409,21.007528 4.56316726,20.9873748 L8.55338078,19.4154314 C8.61476868,19.3952783 8.65569395,19.3952783 8.71708185,19.4154314 L12.7072954,20.9873748 C12.7891459,21.0276811 12.8914591,20.9672217 12.8914591,20.8664561 L12.8914591,19.516197 C12.8914591,19.4557377 12.8709964,19.3952783 12.8096085,19.354972 L10.2927046,17.3598131 L10.3745552,12.1551897 L17.0862989,14.4324923 C17.1681495,14.4526454 17.25,14.3921861 17.25,14.3115736 L17.25,12.215649 C17.25,12.1551897 17.2295374,12.0947303 17.1681495,12.0544241 L10.4768683,6.93553139 L10.5791815,2.05847617 C10.5996441,1.01051389 9.80160142,0.0431640897 8.73754448,0.00285784822 C7.61209964,-0.0576015141 6.67081851,0.84928892 6.69128114,1.95771056 L6.79359431,6.93553139 L0.0818505338,12.0544241 C0.0409252669,12.0947303 -5.91526828e-13,12.1551897 -5.91526828e-13,12.215649 L-5.91526828e-13,14.3115736 C0.0204626335,14.3921861 0.102313167,14.4526454 0.184163701,14.4324923 L6.91637011,12.1350365 Z" id="Page-1-Copy-3" transform="translate(8.625000, 10.500000) rotate(-360.000000) translate(-8.625000, -10.500000) "></path></g></g></g></svg>

</template>

<script>
export default {
  props: ["name"],
};
</script>