<template>
  <section class="parking-types panel-padding" ref="component">
    <div class="inner centre-text cf">
      <header class="parking-types__intro">
        <h2 class="heading">{{ componentData.fields.title }}</h2>
        <div v-html="componentData.fields.intro"></div>
      </header>

      <div
        class="parking-types__airline"
        v-if="!componentData.fields.hideAirlineFilter && optionsAirline.length"
      >
        <label for="airline">{{componentData.fields.filterTitle}}</label>

        <Select2
          :options="optionsAirline"
          class="select2-wrapper"
          style="width: 250px"
          :settings="{
            placeholder: this.labels.value.labelSelectAirline,
          }"
          @select="changeFilter($event)"
          v-if="!componentData.fields.hideAirlineFilter"
          v-model="selectedAirline"
        />
      </div>

      <div class="parking-types__wrapper" :class="'parking-types__wrapper--' + componentData.fields.groupingSize">
        <!-- {{componentData.fields.groupingSize}} -->
        <article
          class="parking-type"
          v-for="(product, index) in componentData.fields.products"
          :key="index"
          v-show="isVisible(product.fields.terminal)"
          :class="product.fields.theme"
        >
          <header :class="{ hasImage: product.fields.imageOverride }">
            <img
              :src="product.fields.imageOverride.fields.umbracoFile.src"
              :alt="product.fields.imageOverride.fields.alt"
              v-if="product.fields.imageOverride"
            />
            <h3 v-if="!product.fields.imageOverride">
              {{ product.fields.title }}
            </h3>
          </header>

          <ol class="parking-type__features">
            <li
              v-for="(distance, index) in product.fields.distances"
              :key="index"
            >
              <i
                v-if="distance.fields.distanceBy === 'Bus'"
                class="fa fa-bus"
              ></i>
              <i
                class="walking"
                v-else-if="distance.fields.distanceBy === 'Walking'"
              ></i>
              <strong>{{ distance.fields.time }}</strong> {{distance.fields.timeSecondary}}
            </li>
            <li
              v-for="(feature, index) in product.fields.features"
              :key="index"
            >
              {{ feature.fields.feature }}
            </li>
          </ol>
          <footer data-match-height="pricing">
            <div v-if="product.fields.priceLink">
              <a
                :href="product.fields.priceLink.url"
                :target="product.fields.priceLink.target"
                class="parking-type__price"
                :class="product.fields.theme"
                v-for="(price, index) in product.fields.prices"
                :key="index"
              >
                <strong>{{ price.fields.price }}</strong>
                {{ price.fields.duration }}</a
              >
            </div>
            <div v-else>
              <span
                class="parking-type__price"
                :class="product.fields.theme"
                v-for="(price, index) in product.fields.prices"
                :key="index"
              >
                <strong>{{ price.fields.price }}</strong>
                {{ price.fields.duration }}</span
              >
            </div>

            <div v-if="product.fields.link">
              <a
                :href="product.fields.link.url"
                :target="product.fields.link.target"
                class="btn btn--primary"
                >{{ product.fields.link.name }}</a
              >
            </div>
          </footer>
        </article>
      </div>
    </div>
    <!--/.inner -->
  </section>
</template>

<script>
import Select2 from "vue3-select2-component";
import matchHeights from "../../mixins/matchHeights";

export default {
  name: "ProductList",
  props: ["componentData"],
  mixins: [matchHeights],
  inject: ["publicSiteSettings", "labels"],
  data() {
    return {
      optionsAirline: this.publicSiteSettings.value.fields.activeAirlines
        ? this.publicSiteSettings.value.fields.activeAirlines.map(function (
            airline
          ) {
            return {
              id: airline.system.id,
              text: airline.system.name,
              terminalID: airline.fields.terminalPicker.fields.terminalID,
            };
          })
        : [],
      selectedAirline: this.componentData.fields.predefinedAirlineFilter
        ? this.componentData.fields.predefinedAirlineFilter.system.id
        : "",
      selectedTerminal: this.componentData.fields.predefinedAirlineFilter
        ? this.componentData.fields.predefinedAirlineFilter.fields
            .terminalPicker.fields.terminalID
        : "",
    };
  },
  methods: {
    changeFilter(item) {
      this.selectedAirline = item.id;
      this.selectedTerminal = item.terminalID;
    },
    isVisible(terminal) {
      if (terminal && terminal.fields.terminalID) {
        return (
          this.selectedTerminal === "" ||
          this.selectedTerminal === terminal.fields.terminalID
        );
      }
      return true;
    },
  },
  components: {
    Select2,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.parking-types {
  &__intro {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }

    p {
      max-width: 540px;
      font-size: 1.6rem;
      line-height: 1.4;
      margin: 0 auto 3rem;

      a {
        color: $skin-colour-primary;
        text-decoration: underline;
      }
    }

    ol,
    ul {
      margin: 3rem auto;
      text-align: left;
      max-width: 540px;

      li {
        position: relative;
        padding-left: 1.5rem;
        font-size: 1.6rem;
        line-height: 1.4;
        margin-bottom: 1.5rem;

        &:last-of-type {
          margin-bottom: 0;
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 8px;
          left: 0;
          width: 6px;
          height: 6px;
          background: $skin-colour-primary;
          border-radius: 50%;
        }
      }
    }
  }

  .inner--narrow {
    padding: 0;
  }

  &__airline {
    margin: 0 auto 50px;
    max-width: 85%;
    text-align: left;

    @include breakpoint($tablet) {
      text-align: center;
    }

    label {
      @include breakpoint($tablet) {
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px 0;
      }
    }

    .select2-container {
      @include breakpoint($tablet) {
        max-width: 260px;
        text-align: left;
      }
    }
  }

  &__wrapper {
    column-gap: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 20px;
  }
}
.parking-type {
  background: $parking-types-bg;
  flex: 0 0 100%;
  max-width: 290px;

  @include breakpoint($tablet-sml){
    flex-basis: calc(50% - 10px);
    max-width: 320px;
  }

  @include breakpoint($tablet) {
    display: flex;
    flex-direction: column;

    .parking-types__wrapper--3 & {
      flex-basis: calc(33.33% - 13.33px);
    }
  }

  @include breakpoint($desktop-sml) {
    .parking-types__wrapper--4 & {
      flex-basis: calc(25% - 15px);
    }
  }

  &.Theme-One header {
    background: $parking-type-theme-1;
  }

  &.Theme-Two header {
    background: $parking-type-theme-2;
  }

  &.Theme-Three header {
    background: $parking-type-theme-3;
  }

  header {
    position: relative;
    padding: 20px;
    text-align: left;

    @include breakpoint($tablet) {
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    // @include breakpoint($desktop) {
    //   padding: 20px;
    // }

    &.hasImage {
      background: none;

      img {
        max-height: 130px;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  h3 {
    font-weight: $bold;
    padding-right: 70px;
    color: $white !important;
    margin: 0 !important;

    @include breakpoint($tablet) {
      font-size: 2.2rem;
      // margin: auto 0 0 0 !important;
    }

    @include breakpoint($desktop) {
      font-size: 3rem;
    }

    span {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 80px;
      height: 75px;

      background: {
        //  image: url("../images/long-stay.png");
        repeat: no-repeat;
        position: 50% 50%;
        size: contain;
      }
    }
  }

  &__features {
    margin: 0;
    padding: 0 20px 20px;
    list-style-type: none;
    text-align: left;

    @include breakpoint($tablet) {
      padding: 0 10px 20px 10px;
    }

    @include breakpoint($desktop) {
      padding: 0 20px 20px 20px;
    }

    li {
      font-size: 1.6rem;
      line-height: 1.3;
      padding: 15px 0;
      border-top: 1px solid $parking-types-features-border;

      i {
        margin-right: 5px;
        height: 21px;
        line-height: 21px;

        &.walking {
          background-image: url("~@/assets/images/walking-man.png");
          display: inline-block;
          width: 11px;
          height: 21px;
          top: 2px;
          position: relative;
        }
      }

      &:first-of-type {
        border-top: none;
      }

      &.parking-type__mode {
        display: flex;
        align-items: center;
        font-size: 1.4rem;

        strong {
          margin-right: 0.5rem;
        }
      }
    }
  }

  &__price {
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 10px;
    // border: 1px solid $parking-map-long-stay;
    color: black;
    font-weight: $reg;
    padding: 15px 10px;

    &:hover,
    &:focus {
      text-decoration: none;
      // background: $parking-map-long-stay;
      color: #fff;
    }

    &.Theme-One {
      border: 1px solid $parking-type-theme-1;

      &:hover,
      &:focus {
        background: $parking-type-theme-1;
      }
    }

    &.Theme-Two {
      border: 1px solid $parking-type-theme-2;

      &:hover,
      &:focus {
        background: $parking-type-theme-2;
      }
    }

    &.Theme-Three {
      border: 1px solid $parking-type-theme-3;

      &:hover,
      &:focus {
        background: $parking-type-theme-3;
      }
    }
  }

  &--long-stay {
    header {
      background: $parking-map-long-stay;
    }

    h3 span {
      //  background-image: url("../images/long-stay-icon.png");
    }
  }

  &--short-stay {
    header {
      background: $parking-map-short-stay;
    }

    h3 span {
      //   background-image: url("../images/short-stay-icon.png");
    }

    .parking-type__price {
      border-color: $parking-map-short-stay;

      &:hover,
      &:focus {
        background: $parking-map-short-stay;
      }
    }
  }

  &--priority {
    header {
      background: $parking-map-priority;
    }

    h3 span {
      //   background-image: url("../images/priority-icon.png");
    }

    .parking-type__price {
      border-color: $parking-map-priority;

      &:hover,
      &:focus {
        background: $parking-map-priority;
      }
    }
  }

  &--unbranded {
    header {
      // background-color: none;
      text-indent: -9999px;

      // background-size: cover;
      // background-repeat: no-repeat;
      // background-position: 50% 50%;
      background {
        size: cover;
        repeat: no-repeat;
        position: 50% 50%;
        color: none;
      }
    }

    h3 span {
      display: none;
    }

    .parking-type__price {
      border-color: #000000;

      &:hover,
      &:focus {
        background: #000000;
      }
    }
  }

  &__price,
  .btn {
    display: block;
    padding: 10px 15px;
  }

  footer {
    display: flex;
    flex-direction: column;
    background: $parking-types-price-footer-bg;
    padding: 15px;
    margin-top: auto;

    div:last-of-type {
      margin-top: auto;
    }
  }
}

// .parking-type {
//   background: $parking-types-bg;
//   width: 100%;
//   max-width: 290px;
//   margin: 0 auto 30px;

//   @include breakpoint($tablet) {
//     display: inline-block;
//     vertical-align: top;
//     margin: 0 1rem 2rem;
//   }

//   header {
//     position: relative;
//     padding: 30px 20px;
//     text-align: left;

//     @include breakpoint($tablet) {
//       padding: 30px 10px;
//     }

//     @include breakpoint($desktop) {
//       padding: 30px 20px;
//     }

//     &.Theme-One {
//       background: $parking-type-theme-1;
//     }

//     &.Theme-Two {
//       background: $parking-type-theme-2;
//     }

//     &.Theme-Three {
//       background: $parking-type-theme-3;
//     }
//   }

//   h3 {
//     font-weight: $bold;
//     padding-right: 70px;
//     color: $parking-types-header !important;
//     margin: 0 !important;

//     @include breakpoint($tablet) {
//       font-size: 2.2rem;
//     }

//     @include breakpoint($desktop) {
//       font-size: 3rem;
//     }

//     span {
//       position: absolute;
//       right: 10px;
//       top: 50%;
//       transform: translateY(-50%);
//       width: 80px;
//       height: 75px;

//       background: {
//         //  image: url("../images/long-stay.png");
//         repeat: no-repeat;
//         position: 50% 50%;
//         size: contain;
//       }
//     }
//   }

//   &__features {
//     margin: 0;
//     padding: 20px;
//     list-style-type: none;
//     text-align: left;

//     @include breakpoint($tablet) {
//       padding: 20px 10px;
//     }

//     @include breakpoint($desktop) {
//       padding: 20px;
//     }

//     li {
//       font-size: 1.6rem;
//       padding: 15px 0;
//       border-top: 1px solid $parking-types-features-border;

//       i {
//         margin-right: 5px;

//         &.walking {
//           background-image: url("/images/walking-man.png");
//           display: inline-block;
//           width: 11px;
//           height: 21px;
//           top: 2px;
//           position: relative;
//         }
//       }

//       &:first-of-type {
//         border-top: none;
//       }

//       &.parking-type__mode {
//         display: flex;
//         align-items: center;
//         font-size: 1.4rem;

//         strong {
//           margin-right: 0.5rem;
//         }
//       }
//     }
//   }

//   &__price {
//     text-align: center;
//     font-size: 1.8rem;
//     margin-bottom: 10px;
//     // border: 1px solid $parking-map-long-stay;
//     color: black;
//     font-weight: $reg;
//     padding: 15px 10px;

//     &:hover,
//     &:focus {
//       text-decoration: none;
//       // background: $parking-map-long-stay;
//       color: #fff;
//     }

//     &.Theme-One {
//       border: 1px solid $parking-type-theme-1;

//       &:hover,
//       &:focus {
//         background: $parking-type-theme-1;
//       }
//     }

//     &.Theme-Two {
//       border: 1px solid $parking-type-theme-2;

//       &:hover,
//       &:focus {
//         background: $parking-type-theme-2;
//       }
//     }

//     &.Theme-Three {
//       border: 1px solid $parking-type-theme-3;

//       &:hover,
//       &:focus {
//         background: $parking-type-theme-3;
//       }
//     }
//   }

//   &--long-stay {
//     header {
//       background: $parking-map-long-stay;
//     }

//     h3 span {
//       //  background-image: url("../images/long-stay-icon.png");
//     }
//   }

//   &--short-stay {
//     header {
//       background: $parking-map-short-stay;
//     }

//     h3 span {
//       //   background-image: url("../images/short-stay-icon.png");
//     }

//     .parking-type__price {
//       border-color: $parking-map-short-stay;

//       &:hover,
//       &:focus {
//         background: $parking-map-short-stay;
//       }
//     }
//   }

//   &--priority {
//     header {
//       background: $parking-map-priority;
//     }

//     h3 span {
//       //   background-image: url("../images/priority-icon.png");
//     }

//     .parking-type__price {
//       border-color: $parking-map-priority;

//       &:hover,
//       &:focus {
//         background: $parking-map-priority;
//       }
//     }
//   }

//   &--unbranded {
//     header {
//       // background-color: none;
//       text-indent: -9999px;

//       // background-size: cover;
//       // background-repeat: no-repeat;
//       // background-position: 50% 50%;
//       background {
//         size: cover;
//         repeat: no-repeat;
//         position: 50% 50%;
//         color: none;
//       }
//     }

//     h3 span {
//       display: none;
//     }

//     .parking-type__price {
//       border-color: #000000;

//       &:hover,
//       &:focus {
//         background: #000000;
//       }
//     }
//   }

//   &__price,
//   .btn {
//     display: block;
//     border-radius: 5px;
//     padding: 10px 15px;
//   }

//   footer {
//     background: $parking-types-price-footer-bg;
//     padding: 15px;
//   }
// }
</style>
