<template>
  <div
    class="hero-slider__slide"
    :style="{
      backgroundImage:
        'url(' + heroData.fields.image.fields.umbracoFile.src + ')',
    }"
  >
    <div class="inner">
      <div class="hero__content">
        <h1 class="hero__heading">{{ heroData.fields.title }}</h1>
        <p>{{ heroData.fields.subtitle }}</p>
      </div>
    </div>
    <div class="hero__gradient"></div>
  </div>
</template>

<script>
export default {
  name: "HeroPlain",
  props: ["heroData"],
};
</script>

<style lang="scss">
.hero {
  position: relative;

  background: {
    position: center center;
    repeat: no-repeat;
    size: cover;
  }

  &__gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-image: linear-gradient(
      -107deg,
      rgba(0, 0, 0, 0.46) 0%,
      rgba(0, 0, 0, 0.13) 93%
    );
  }

  &__heading {
    color: #fff;
    @extend h1;

    @include breakpoint($tablet) {
      max-width: 75%;
    }

    @include breakpoint($desktop-sml) {
      max-width: none;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 72rem;

    @include breakpoint($desktop) {
      max-width: 82rem;
    }
  }

  p {
    font-size: 1.9rem;
    margin-top: -1.5rem;
    font-family: $font-headings;
    margin-bottom: 3rem;
    color: $white;

    @include breakpoint($tablet) {
      max-width: 75%;
    }

    @include breakpoint($desktop-sml) {
      font-size: 3rem;
      line-height: 1.2;
      max-width: none;
    }
  }
}
</style>
