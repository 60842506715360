<template>
  <div class="inner">
    <div class="flight-search cf">

      <h2><span><i><Icon :name="'plane'" /></i></span> {{ componentData.fields.title }}
      </h2>

      <form action="" class="search-flight">
        <div class="form__row">
          <input type="text" data-root="" data-detail-page="/flightpage"
            data-search-page="/flights" :data-desktop-placeholder="componentData.fields.desktopPlaceholderText"
            :data-mobile-placeholder="componentData.fields.mobilePlaceholderText"
            :placeholder="componentData.fields.desktopPlaceholderText"
            v-model="searchTerm"
            @keypress="focusBox">
          <input type="button" class="btn btn--squared" data-search-flight-btn>
        </div>
        <ul class="search-flight-results" v-if="searchTerm.length >= 2 && this.filteredFlights.length > 0">
          <li class="search-flight-result ui-menu-item"
              v-for="(flight, index) in this.filteredFlights"
              :key="index">
            <a :href="flight.flightDetailsPageUrl" :title="`Find out more about ${flight.location}-${flight.flightNumber}`" id="ui-id-3" tabindex="-1" class="ui-menu-item-wrapper">
              <div class="search-flight-result__date">{{ flight.arriving ? this.labels.value.labelArrival : this.labels.value.labelDeparture }}</div>
              <div class="search-flight-result__time">{{ formatDateTime(flight.scheduledDateTime, 'time') }}</div>
              <div class="search-flight-result__airline"><img :src="flight.airlineLogo" :alt="flight.airlineName"></div>
              <div class="search-flight-result__number">{{ flight.flightNumber }}</div>
              <div class="search-flight-result__destination">{{ flight.location }}</div>
              <div class="search-flight-result__icon"><i><Icon :name="'arrow'" /></i></div>
            </a>
          </li>
          <div class="search-flight-result__more"><a :href="`/flight-info/?search=${searchTerm}`" class="btn btn--secondary">All results</a></div>
        </ul>
      </form>

      <a class="search-flight__mobile" href="#search-flights" tabindex="0">
        <span>Flight number or destination</span>
        <i class="btn btn--squared"></i>
      </a>

      <a href="/flights/" class="btn btn--link">
        <i><Icon :name="'arrow'" /></i>{{ componentData.fields.viewAllText }}
      </a>
    </div>
    <section class="modal modal--flights" id="search-flights" tabindex="-1" role="dialog" aria-labelledby="modal-label" aria-hidden="false" data-stackable="false">
        <div class="modal-inner">
            <div class="modal-content">
                <form action="" class="search-flight-mobile ng-pristine ng-valid">
                    <div class="form__row">
                        <input type="text" data-root="/Umbraco/api/FlightsApi/RetrieveFlights"
                            :placeholder="componentData.fields.mobilePlaceholderText"
                            v-model="searchTerm">
                        <input type="button" class="btn btn--squared" data-search-flight-btn="">
                    </div>
                    <div data-search-flight-target-element="">
                        <ul id="ui-id-7" tabindex="0" class="ui-menu ui-widget ui-widget-content ui-autocomplete ui-front" v-if="searchTerm.length >= 2 && this.filteredFlights.length > 0">
                            <li class="search-flight-result ui-menu-item"
                                v-for="(flight, index) in this.filteredFlights"
                                  :key="index">
                                <a :href="flight.flightDetailsPageUrl" :title="`Find out more about ${flight.location}-${flight.flightNumber}`" id="ui-id-50" tabindex="-1" class="ui-menu-item-wrapper">
                                    <div class="search-flight-result__date">{{ flight.arriving ? this.labels.value.labelArrival : this.labels.value.labelDeparture }}</div>
                                    <div class="search-flight-result__time">{{ formatDateTime(flight.scheduledDateTime, 'time') }}</div>
                                    <div class="search-flight-result__airline">
                                        <img :src="flight.airlineLogo" :alt="flight.airlineName" />
                                    </div>
                                    <div class="search-flight-result__number">{{ flight.flightNumber }}</div>
                                    <div class="search-flight-result__destination">{{ flight.location }}</div>
                                    <div class="search-flight-result__icon"><i><Icon :name="'arrow'" /></i></div>
                                </a>
                            </li>
                            <div class="search-flight-result__more">
                                <a :href="`/flight-info/?search=${searchTerm}`" class="btn btn--keyline-alt">All results</a>
                            </div>
                        </ul>
                    </div>
                </form>
            </div>
            <a href="#!" title="Close this modal" data-dismiss="modal" data-close="Close" class="icon-close" style="background-image: none;"><!--?xml version="1.0" encoding="UTF-8" standalone="no"?--><svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">        <title>close icon</title>    <desc>Created with Sketch.</desc>    <defs></defs>    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">        <g id="Icons/MobNav-close" transform="translate(-5.000000, -5.000000)" fill="#FFFFFF">            <path d="M13.2727273,12 L19,6.27272727 L17.7272727,5 L12,10.7272727 L6.27272727,5 L5,6.27272727 L10.7272727,12 L5,17.7272727 L6.27272727,19 L12,13.2727273 L17.7272727,19 L19,17.7272727 L13.2727273,12 Z" id="close-icon"></path>        </g>    </g></svg></a>
        </div>
        <a href="#!" class="modal-close" title="Close this modal" data-dismiss="modal" data-close="Close">×</a>
    </section>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import axios from "axios";
import dayjs from "dayjs";
export default {
  name: "LiveFlightSearchBlock",
  props: ["componentData"],
  components: {
    Icon,
  },
  inject: ["publicSiteSettings", "labels"],
  data() {
    return {
      searchTerm: '',
      flights: [],
      displayFormat: this.publicSiteSettings.value.fields.globalLocale === "en-US" ? "MM/DD/YYYY" : "DD/MM/YYYY",
      timeFormat: this.publicSiteSettings.value.fields.globalLocale === "en-US" ? "h:mm a" : "H:mm"
    }
  },
  methods: {
    formatDateTime(incomingDate, returnType)
    {
      let returnFormat = this.displayFormat + " " + this.timeFormat;
      switch (returnType){
        case 'date':
          returnFormat = this.displayFormat;
          break;
        case 'time':
          returnFormat = this.timeFormat;
          break;
      }
      return dayjs(incomingDate).format(returnFormat);
    }
  },
  computed: {
    filteredFlights: function() {
      return this.flights.arrivals.concat(this.flights.departures).filter(flight => {
        return flight.location.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            flight.flightNumber.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            flight.airlineName.toLowerCase().includes(this.searchTerm.toLowerCase());
      }).slice(0,3);
    }
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_REMOTEBASEURL +
              "/api/liveflightsapi/retrieveliveflights?iataCode=" +
              process.env.VUE_APP_IATACODE
      )
      .then((response) => {
        this.flights = response.data;
      });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.flight-search {
    border: 1px solid #DEDEDE;
    padding: 2rem 1.5rem;
    max-width: 80rem;
    margin: 3.5rem auto;

    @include breakpoint($desktop-sml) {
        margin: 7rem auto 0;
        padding: 3rem;
    }

    .search-flight {
        display: none;

        @include breakpoint($tablet) {
            display: unset;
        }
    }

    a {
      text-decoration: none;
    }

    h2 {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;

        span {
            background: $skin-colour-primary;
            border-radius: 50%;
            width: 5rem;
            height: 5rem;
            position: relative;
            margin-right: .5rem;

            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                svg {
                    display: block;

                    path {
                        fill: $white;
                    }
                }
            }
        }
    }

    .btn--link {
        float: right;
        margin-top: 2rem;
        margin-left: 2rem;
    }

    .btn--squared {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 50px;
        min-width: 0;
        display: inline-block;
        padding: 1.45rem 0;
        border-color: $search-flight-btn-border;
        @include retina-js("~@/assets/images/search-icon", png, 25px, 25px);

        background: {
            position: 50% 50%;
            repeat: no-repeat;
            color: $search-flight-btn-bg;
        }

        &:hover,
        &:focus {
            background-color: darken($search-flight-btn-border, 5%);
        }
    }

    .search-flight__mobile {
        position: relative;
        height: 50px;
        line-height: 50px;
        border: 1px solid #DEDEDE;
        border-radius: 2px;
        padding-left: 1rem;
        display: block;
        width: 100%;
        background: $search-flight-mobile-bg;
        color: $form-placeholder;
        font-size: 1.8rem;
        font-family: $font-base;

        @include breakpoint($tablet) {
            display: none;
        }

        .btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            height: 50px;
            min-width: 0;
            display: inline-block;
            padding: 1.45rem 0;
            border-color: $search-flight-btn-border;
            @include retina-js("~@/assets/images/search-icon", png, 25px, 25px);

            background: {
                position: 50% 50%;
                repeat: no-repeat;
                color: $skin-colour-secondary;
            }

            &:hover,
            &:focus {
                background-color: darken($search-flight-btn-bg, 5%);
            }
        }
    }

}

.search-flight-results {
  position: absolute;
  border: solid 1px #dedede;
  margin-top: -2.1rem;
  background-color: #ffffff;
}

.search-flight-result {
    a {
        width: 100%;
        display: table;
        padding: 2.4rem 2rem;
        border-bottom: 1px solid $keyline;

        &:hover {
            background: $grey-bg;
        }
    }

    div {
        display: table-cell;
        vertical-align: middle;
        text-align: left;
        width: 9rem;
        padding-right: 1rem;
        font-size: 1.8rem;
        color: $base-font-color;

        &.search-flight-result__destination {
            width: 20.5rem;
        }

        &.search-flight-result__icon {
            width: 2.5rem;
            padding-right: 0;
        }
    }

    &__airline {
        img {
            max-width: 70px;
            max-height: 20px;
        }
    }

    i {
        display: block;
        margin: 0 auto;
    }

    &:last-child {
        a {
            border-bottom: 0;
        }
    }

    &__more {
        padding: 2rem;
        background: $grey-bg;
    }
}

#search-flight-mobile-results {
    position: relative;
}

.search-flight-mobile {

    .form__row {
        margin: 2rem 0;
    }

    li,
    .search-flight-result__more {
        margin-left: -2rem;
        margin-right: -2rem;
    }

    input[type="text"] {
        width: calc(100% - 50px);
        display: inline-block;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .btn:not(.btn--keyline-alt) {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 50px;
        min-width: 0;
        display: inline-block;
        padding: 1.45rem 0;
        border-color: $search-flight-btn-border;
        margin: 0;
        @include retina-js("~@/assets/images/search-icon", png, 25px, 25px);

        background: {
            position: 50% 50%;
            repeat: no-repeat;
            color: $search-flight-btn-bg;
        }

        &:hover,
        &:focus {
            background-color: darken($search-flight-btn-bg, 5%);
        }
    }

    .search-flight-result {
        a {
            padding: 1.5rem 2rem;

            @include breakpoint($palm) {
                padding: 2rem;
            }
        }

        div {
            display: table-cell;
            vertical-align: middle;
            text-align: left;
            padding: 1rem 0;
            font-size: 1.6rem;
            color: $search-flight-result-color;
            float: left;
            width: 30%;

            @include breakpoint($palm) {
                padding: 1.5rem 0;
                font-size: 1.65rem;
            }

            &.search-flight-result__destination {
                width: 60%;
            }

            &.search-flight-result__airline {
                width: 40%;
            }

            &.search-flight-result__icon {
                width: 10%;
                padding: 1.4rem 0;
            }

            &.search-flight-result__icon,
            &.search-flight-result__destination,
            &.search-flight-result__number {
                background: $grey-bg;
            }

            &.search-flight-result__number,
            &.search-flight-result__date {
                padding-left: .5rem;

                @include breakpoint($palm) {
                    padding-left: 1rem;
                }
            }

        }
    }
}

</style>
