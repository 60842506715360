import dayjs from "dayjs";
import axios from "axios";

export default {
    inject: ["publicSiteSettings", "labels"],  
    methods: {
      submitForm(e) {
        var minimumNotice = parseInt(this.publicSiteSettings.value.fields.aeroParkerParkingMinimumNotice) || 0;
        var minimumTime = this.publicSiteSettings.value.fields.timezone ? new Date(this.publicSiteSettings.value.fields.timezone.currentTime) : new Date();
        minimumTime.setMinutes(minimumTime.getMinutes() + minimumNotice); 
        if (this.entryDateAndTime < minimumTime || this.exitDateAndTime < minimumTime)
        {
          var timeValidationMessage = "";
          if (minimumNotice === 0)
          {
              timeValidationMessage = this.labels.value.validationTimeGeneric;
          }
          else if (minimumNotice % 60 === 0)
          {
              timeValidationMessage = this.labels.value.validationTimeHours.replace('{0}', minimumNotice / 60);
          }
          else
          {
              timeValidationMessage = this.labels.value.validationTimeMinutes.replace('{0}', minimumNotice); 
          }
          alert(timeValidationMessage);
          e.preventDefault();
        }
        else if (this.exitDateAndTime <= this.entryDateAndTime)
        {
          alert(this.labels.value.validationTimeOrder);
          e.preventDefault();
        }
        else
        {
          e.target.action = this.postUrl; 
        }
      },
      updateTeaserPrice() {
        if (this.entryDate > this.exitDate)
        {
          this.exitDate = this.entryDate;
        }        
        if (this.exitDateAndTime > this.entryDateAndTime)
        {
          axios
          .get(
            process.env.VUE_APP_REMOTEBASEURL +  "/api/AeroparkerBookingApi/RetrieveLowestParkingPrice?iataCode=" + process.env.VUE_APP_IATACODE +
            "&entryDateTime=" + dayjs(this.entryDateAndTime).format("YYYY-MM-DD HH:mm") +
            "&exitDateTime=" + dayjs(this.exitDateAndTime).format("YYYY-MM-DD HH:mm")
          )
          .then((response) => {
            this.teaserPrice = response.data;
          });
        }
        else
        {
          this.teaserPrice = '';
        }        
      },
      updateTerminal() {
        this.terminal = this.airlineTerminal.split("_")[1]; // TODO - work out better way of passing independent terminal value. May need different select dropdown
      },
      buildTimeslots(){
        // Build Timeslots
        var slotStart = this.publicSiteSettings.value.fields.aeroParkerParkingSlotStart || "00:00";
        var slotStartHour = parseInt(slotStart.split(":")[0]) || 0;
        var slotStartMins = parseInt(slotStart.split(":")[1]) || 0;
        var slotEnd = this.publicSiteSettings.value.fields.aeroParkerParkingSlotEnd || "24:01";
        var slotEndHour = parseInt(slotEnd.split(":")[0]) || 23;
        var slotEndMins = parseInt(slotEnd.split(":")[1]) || 59;				
        var slotLength = parseInt(this.publicSiteSettings.value.fields.aeroParkerParkingSlotLength) || 60;
        var startOfDayAdjustment = true;
        var formatString = (this.publicSiteSettings.value.fields.twelveHourClockFormat || false) ? "h:mm a" : "HH:mm"
        var timeslots = [];
        for (let hours = slotStartHour; hours <= slotEndHour; hours++)
        {
          for (let mins = (hours == slotStartHour ? slotStartMins : 0); mins < (hours == slotEndHour ? slotEndMins : 60); mins += slotLength)
          {
            let friendlyStartTime = String("00" + hours).slice(-2) + ":" + String("00" + mins).slice(-2);
            if (friendlyStartTime == "00:00" || friendlyStartTime == "24:00")
            {
              friendlyStartTime = startOfDayAdjustment ? "00:01" : "23:59";
              startOfDayAdjustment = false;
            }
            timeslots.push(friendlyStartTime);
          }
        }
        var timeslotsFriendly = timeslots.map(function(time) {
          return { id: time, text: dayjs("1970-01-01 " + time).format(formatString) };
        });
        return timeslotsFriendly; 
      }
    },
    computed: {
      entryDateAndTime: function () {
        return new Date(this.entryDate.getFullYear(), this.entryDate.getMonth(),this.entryDate.getDate(), this.entryTime.split(':')[0], this.entryTime.split(":")[1]);
      },
      exitDateAndTime: function() {
        return new Date(this.exitDate.getFullYear(), this.exitDate.getMonth(),this.exitDate.getDate(), this.exitTime.split(':')[0], this.exitTime.split(":")[1]);
      },
      entryDateNormalised: function() {
        return dayjs(this.entryDate).format(this.displayFormat);
      },
      exitDateNormalised: function() {
        return dayjs(this.exitDate).format(this.displayFormat);
      }
    },
    data() {
      return {
        entryDate: new Date(),
        exitDate: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000),        
        entryTime: '',
        exitTime: '',
        promoCode: '',
        terminal: -1,
        airlineTerminal: '',
        postUrl: this.publicSiteSettings.value.fields.aeroParkerParkingUrl,
        currency: this.publicSiteSettings.value.fields.globalLocale === "en-US" ? "$" : "£", // TODO - move this somewhere more central, with broader format support
        inputFormat: this.publicSiteSettings.value.fields.globalLocale === "en-US" ? "MM/dd/yyyy" : "dd/MM/yyyy", // TODO - move this somewhere more central, with broader format support
        displayFormat: this.publicSiteSettings.value.fields.globalLocale === "en-US" ? "MM/DD/YYYY" : "DD/MM/YYYY", // TODO - move this somewhere more central, with broader format support
        optionsAirline: this.publicSiteSettings.value.fields.activeAirlines ? this.publicSiteSettings.value.fields.activeAirlines.map(function (airline) {
          return { id: airline.system.id + "_" + airline.fields.terminalPicker.fields.terminalID, text: airline.system.name };
        }) : [],
        teaserPrice: '',
        optionsTime: this.buildTimeslots()
      }        
    }      
  }
