<template>
  <div class="pinned-parking">
    <!-- Using StickyBookingBar as base for reference for dropdowns, datepickers etc. Will appear pinned when scrolling, or as the More general tabbed bar when near top (ie sat between hero and components) -->
    <!-- Do not change the pinned-parking and parking-widget classes for this, but instead replace them with something new for this component -->

    <section class="parking-widget parking-widget--pinned cf">
      <header>
        <span class="pinned-parking__icon"></span>
        <h2 class="pinned-parking__title">
          SALES BAR STUB COMPONENT:
        </h2>
      </header>

      <div class="form form--inline" data-role="parking-form-wrapper">
        <fieldset>
          <div class="date">
            <label for="entry">{{this.labels.value.labelEntryDate}}</label>

            <DatePicker
              v-model="entryDate"
              :min-date="new Date()"
              :masks="{ input: this.displayFormat }"
              class="datepicker"
              :popover="{ visibility: 'focus' }"
              @change="this.updateTeaserPrice"
              is-required
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </DatePicker>

            <Select2
              class="selectbox-time"
              :options="optionsTime"
              :settings="{
                placeholder: this.labels.value.labelTime,
              }"
              v-model="entryTime"
              @select="updateTeaserPrice"
            />
          </div>
          <div class="date">
            <label for="exit">{{this.labels.value.labelExitDate}}</label>

            <DatePicker
              v-model="exitDate"
              :min-date="entryDate"
              :masks="{ input: this.displayFormat }"
              class="datepicker"
              :popover="{ visibility: 'focus' }"
              @change="this.updateTeaserPrice"
              is-required
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </DatePicker>

            <Select2
              class="selectbox-time"
              :options="optionsTime"
              :settings="{
                placeholder: this.labels.value.labelTime,
              }"
              v-model="exitTime"
              @select="updateTeaserPrice"
            />
          </div>
          <div class="terminal">
            <label for="terminal">{{this.labels.value.labelAirline}}</label>

            <Select2
              id="terminal"
              :options="optionsAirline"
              :settings="{
                placeholder: this.labels.value.labelSelectAirline,
              }"
              v-model="airlineTerminal"
              @select="updateTerminal"
            />
          </div>
          <div class="button">
            <form
              method="post"
              novalidate="novalidate"
              data-role="parking-form"
              v-on:submit="submitForm"
            >
              <input type="hidden" value="1" name="parkingDetailsSubmitted" />
              <input type="hidden" value="1" name="progressToNextStep" />
              <input type="hidden" name="terminal" v-model="terminal" />
              <input type="hidden" value="" name="promocodes" />
              <input
                type="hidden"
                name="entryDate"
                v-model="entryDateNormalised"
              />
              <input type="hidden" name="entryTime" v-model="entryTime" />
              <input
                type="hidden"
                name="exitDate"
                v-model="exitDateNormalised"
              />
              <input type="hidden" name="exitTime" v-model="exitTime" />
              <input type="submit" class="btn btn--primary" :value="this.labels.value.buttonBook" />
            </form>
          </div>
          <p class="price-from" v-if="teaserPrice">
            {{this.labels.value.labelPriceTeaser.replace('{0}', this.currency + this.teaserPrice)}}
          </p>
        </fieldset>
      </div>
    </section>
    <!--/.parking-widget -->
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import { DatePicker } from "v-calendar";
import parkingForm from "../mixins/parkingForm";

export default {
  name: "StickySalesBar",
  components: {
    Select2,
    DatePicker,
  },
  data() {
    return {
      entryDate: new Date(),
      exitDate: new Date(),
    };
  },
  mixins: [parkingForm],
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: () => {
      let scrollPos = window.scrollY;
      let stickyBar = document.querySelector(".pinned-parking");

      scrollPos > 600
        ? stickyBar.classList.add("pinned-parking--fixed")
        : stickyBar.classList.remove("pinned-parking--fixed");

      // if (scrollPos > 600) {
      //   stickyBar.classList.add("pinned-parking--fixed");
      // } else {
      //   stickyBar.classList.remove("pinned-parking--fixed");
      // }
    },
  },
};
</script>

<style lang="scss">
@keyframes inpage-search-slide {
  0% {
    top: -70px;
  }

  30% {
    top: -50px;
  }

  100% {
    top: 0;
  }
}

.pinned-parking {
  position: absolute;
  z-index: 9997; // Less than Cookie Modal and Modals
  top: -80px;
  left: 0;
  right: 0;
  background: $pinned-parking-bg;
  height: 80px;
  padding: 8px 10px;
  display: none;

  @include breakpoint($desktop-sml-and-below) {
    display: none !important;
  }

  @include breakpoint($desktop-sml) {
    display: block;
  }

  @include breakpoint($desktop) {
    padding: 10px 30px;
    height: 85px;
    top: -85px;
  }

  &--fixed {
    position: fixed;
    top: 0;
    -webkit-animation: inpage-search-slide 500ms 1;
    animation: inpage-search-slide 500ms 1;

    @include breakpoint($desktop) {
      top: 0;
    }
  }

  &__icon {
    transform: scale(0.75);
    width: 64px;
    height: 64px;

    background: {
      position: 50% 50%;
      size: contain;
      repeat: no-repeat;
      image: url("~@/assets/images/parking-icon-white.png");
    }

    @include retina-js-img("~@/assets/images/parking-icon-white", png, 64px, 64px);

    display: none;
    vertical-align: middle;

    @include breakpoint($wide) {
      display: inline-block;
    }
  }

  &__title {
    font-size: 1.4rem;
    line-height: 1.5;
    color: $pinned-parking-color;
    font-weight: $light;
    display: inline-block;
    vertical-align: middle;
    margin: 0 !important;
    width: 190px;
    overflow: hidden;
    max-height: 45px;

    @include breakpoint($desktop) {
      font-size: 1.5rem;
      text-align: left;
    }
  }

  .parking-widget {
    background: none;
    padding: 0;
    max-width: 1400px;
    margin: 0 auto;
    text-align: center;

    header {
      float: none;
      top: 10px;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      padding-right: 10px;
    }

    .form {
      float: none;
      margin: 0;
      display: inline-block;
      vertical-align: middle;

      .btn {
        min-width: 0;
        font-size: 1.4rem;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        line-height: 40px;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        top: 10px;
        position: relative;

        @include breakpoint($desktop) {
          font-size: 1.6rem;
        }
      }

      fieldset {
        display: table;
        width: 100%;
        padding: 0;
        margin: 0;
        border: none;

        .date,
        .terminal,
        .price-from,
        .button {
          display: table-cell;
          vertical-align: middle;
        }

        .date,
        .terminal,
        .button {
          padding-right: 10px;
        }
      }

      p {
        color: $pinned-parking-color;
        font-size: 1.4rem;
        font-weight: $light;
        margin-left: 10px;
        font-weight: $reg;

        @include breakpoint($desktop) {
          font-size: 1.5rem;
          text-align: left;
          padding-top: 20px;
        }
      }

      .date {
        padding-right: 5px;

        .v3dp__datepicker {
          display: inline-block;
        }

        .selectbox-time {
          width: 110px !important;
          text-align: left;
          // left: -5px;
          border-left: 1px solid #e0e4f0;
          display: inline-block;

          @include breakpoint($desktop) {
            width: 120px !important;
          }

          .select2 {
            width: 100% !important;
          }
        }
      }

      .terminal {
        .select2 {
          text-align: left;
          width: 140px !important;

          @include breakpoint($desktop) {
            width: 180px !important;
          }
        }
      }

      .price-from {
        text-align: left;
        width: 100px;
        padding-top: 22px;
        vertical-align: initial;
      }

      .select2-container--default .select2-selection--single {
        font-size: 1.4rem;
        height: 40px;
        line-height: 40px;
        border: none;

        @include breakpoint($desktop) {
          font-size: 1.6rem;
        }
      }

      .select2-results__option {
        font-size: 1.4rem;

        @include breakpoint($desktop) {
          font-size: 1.6rem;
        }
      }

      .select2-selection__arrow {
        height: 40px;
      }

      .select2-container--default
        .select2-selection--single
        .select2-selection__rendered {
        line-height: 40px;
        border: none;
      }

      select {
        appearance: none;
      }

      .datepicker {
        //    margin-right: 5px;
        vertical-align: middle;
        display: inline-block;

        input {
          font-size: 1.4rem;
          height: 40px;
          line-height: 40px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          border-right: none;
          width: 110px !important;

          @include breakpoint($desktop) {
            font-size: 1.6rem;
            width: 130px !important;
          }
        }
      }

      input[type="text"],
      input[type="date"] {
        width: 110px !important;
        //    margin-right: 5px;
        vertical-align: middle;
        display: inline-block;
        font-size: 1.4rem;
        height: 40px;
        line-height: 40px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: none;

        @include breakpoint($desktop) {
          width: 130px !important;
          font-size: 1.6rem;
        }
      }

      input[type="button"] {
        padding: 7px 10px;

        @include breakpoint($desktop) {
          padding: 7px 20px;
        }
      }

      label {
        width: auto;
        display: block;
        font-weight: $light;
        font-size: 1.4rem;
        color: $pinned-parking-color;
        margin-bottom: 5px;
        text-transform: none;
        text-align: left;
      }

      // div {
      //   margin: 0;
      // }
    }
  }
}
</style>
