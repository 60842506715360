<template>
  <div class="inner">
    <h1>Style Guide</h1>

    <section class="style-guide-panel">
      <h1 class="style-guide-panel__heading">Typography</h1>

      <h2 class="pretitle">PRE TITLE - 14px(m) / 16px(d)</h2>
      <h1>This is a heading 1- 36px(m) / 42px(d)</h1>
      <h2>This is a heading 2- 24px(m) / 32px(d)</h2>
      <h3>This is a heading 3- 20px(m) / 22px(d)</h3>
      <h4>This is a heading 4- 16px(m) / 18px(d)</h4>

      <div style="background: black">
        <h1 class="banner-title">Banner title- 32px(m) / 46px(d)</h1>
      </div>
    </section>

    <section class="style-guide-panel">
      <h1 class="style-guide-panel__heading">Buttons</h1>
      <a href="#nogo" class="btn btn--primary">Primary Btn</a>
      <a href="#nogo" class="btn btn--secondary">Secondary Btn</a>

      <a href="#nogo" class="btn btn--link">Btn Links</a>
      <a href="#nogo" class="btn btn--keyline">Btn Keyline</a>
      <a href="#nogo" class="btn btn--keyline-alt">Btn Keyline Alt</a>
      <a href="#nogo" class="btn btn--keyline btn--small">Btn Small</a>
      <a href="#nogo" class="btn btn--primary btn--squared"
        >Btn Primary Btn Squared</a
      >
      <a href="#nogo" class="btn btn--secondary btn--block"
        >Btn Secondary Btn Block</a
      >
    </section>

    <section class="style-guide-panel typeset">
      <h1 class="style-guide-panel__heading">Typeset</h1>

      <h1>This is a heading 1- 36px(m) / 42px(d)</h1>
      <h2>This is a heading 2- 24px(m) / 32px(d)</h2>
      <h3>This is a heading 3- 20px(m) / 22px(d)</h3>
      <h4>This is a heading 4- 16px(m) / 18px(d)</h4>

      <p>
        Lorem ipsum <a href="#">ipsum dolor</a> amet, consectetur adipisicing
        elit. Veniam numquam inventore, eveniet totam aperiam hic delectus
        facilis, repellendus et repellat quaerat necessitatibus dicta
        consequatur quasi,
      </p>

      <ul>
        <li>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam
          numquam inventore
        </li>
        <li>
          Lorem <a href="#">ipsum dolor</a> sit amet, consectetur adipisicing
          elit. Veniam numquam inventore
        </li>
        <li>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam
          numquam inventore
        </li>
        <li>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam
          numquam inventore
        </li>
      </ul>

      <ol>
        <li>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam
          numquam inventore
        </li>
        <li>
          Lorem <a href="#">ipsum dolor</a> sit amet, consectetur adipisicing
          elit. Veniam numquam inventore
        </li>
        <li>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam
          numquam inventore
        </li>
        <li>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam
          numquam inventore
        </li>
      </ol>

      <hr />

      <p class="big-text">
        Big Text - Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        Veniam numquam inventore, eveniet totam aperiam hic delectus facilis,
        repellendus et repellat quaerat necessitatibus dicta consequatur quasi,
        saepe asperiores doloribus sequi illum!- 19px(m) / 22px(d)
      </p>

      <p class="small-text">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam numquam
        inventore, eveniet totam aperiam hic delectus facilis, repellendus et
        repellat quaerat necessitatibus dicta consequatur quasi, saepe
        asperiores doloribus sequi illum!- 13px(m) / 15px(d)
      </p>
    </section>

    <p class="big-text">
      Big Text - Lorem ipsum dolor sit amet, consectetur adipisicing elit.
      Veniam numquam inventore, eveniet totam aperiam hic delectus facilis,
      repellendus et repellat quaerat necessitatibus dicta consequatur quasi,
      saepe asperiores doloribus sequi illum!- 19px(m) / 22px(d)
    </p>

    <p class="small-text">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam numquam
      inventore, eveniet totam aperiam hic delectus facilis, repellendus et
      repellat quaerat necessitatibus dicta consequatur quasi, saepe asperiores
      doloribus sequi illum!- 13px(m) / 15px(d)
    </p>

    <h1>Icons</h1>

    <Icon name="arrow" class="arrow-svg" />

    <Icon name="360view" class="arrow-svg" />
  </div>
</template>

<script>
import Icon from "@/components/Icon";

export default {
  name: "Playground",
  components: {
    Icon,
  },
};
</script>

<style scoped></style>
